import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import { Container, Box } from "@chakra-ui/react";


// Core Pages (precached)
import Header from '../core/header'
import Home from '../core/home'


// Main Pages
const Hajj = React.lazy(() => import('../components/hajj/hajj'))
const Hajj08 = React.lazy(() => import('../components/hajj/hajj-08'))
const Hajj09 = React.lazy(() => import('../components/hajj/hajj-09'))
const Hajj10 = React.lazy(() => import('../components/hajj/hajj-10'))
const Hajj11 = React.lazy(() => import('../components/hajj/hajj-11'))
const Hajj12 = React.lazy(() => import('../components/hajj/hajj-12'))
const Hajj13 = React.lazy(() => import('../components/hajj/hajj-13'))

const HajjMistakes = React.lazy(() => import('../components/hajj/hajj-mistakes'))
const HajjSupplications = React.lazy(() => import('../components/hajj/hajj-supplications'))
const HajjNotes = React.lazy(() => import('../components/hajj/hajj-notes'))


//Umrah with Sub pages
const Umrah = React.lazy(() => import('../components/umrah/umrah'));
const Umrah1 = React.lazy(() => import('../components/umrah/umrah-01-general'));
const Umrah2 = React.lazy(() => import('../components/umrah/umrah-02-meeqat'));
const Umrah3 = React.lazy(() => import('../components/umrah/umrah-03-ihraam'));
const Umrah4 = React.lazy(() => import('../components/umrah/umrah-04-tawaaf'));
const Umrah5 = React.lazy(() => import('../components/umrah/umrah-05-sai'));
const Umrah6 = React.lazy(() => import('../components/umrah/umrah-06-shaving'));


// Sections
const History = React.lazy(() => import('../components/history'));
const Dua = React.lazy(() => import('../components/dua'));
const POI = React.lazy(() => import('../components/poi'));
const Lectures = React.lazy(() => import('../components/lectures'));


// Common Pages
const About = React.lazy(() => import('../common/about'));
const Changelog = React.lazy(() => import('../common/changelog'));
const Contact = React.lazy(() => import('../common/contact'));
const Page404 = React.lazy(() => import('../common/404'));



const AppRoutes = () => {

	return (

		<BrowserRouter>

			<Header />

			<Suspense fallback={
				<Container maxW='container.lg' mt={10} mb={20} centerContent>
					<Box>Loading...</Box>
				</Container>
			}>

				<Routes>

					<Route path="/umrah" element={<Umrah />} />
					<Route path="/umrah/umrah-1-general" element={<Umrah1 />} />
					<Route path="/umrah/umrah-2-meeqat" element={<Umrah2 />} />
					<Route path="/umrah/umrah-3-ihraam" element={<Umrah3 />} />
					<Route path="/umrah/umrah-4-tawaaf" element={<Umrah4 />} />
					<Route path="/umrah/umrah-5-sai" element={<Umrah5 />} />
					<Route path="/umrah/umrah-6-shaving" element={<Umrah6 />} />

					<Route path="/hajj" element={<Hajj />} />
					<Route path="/hajj/hajj-08" element={<Hajj08 />} />
					<Route path="/hajj/hajj-09" element={<Hajj09 />} />
					<Route path="/hajj/hajj-10" element={<Hajj10 />} />
					<Route path="/hajj/hajj-11" element={<Hajj11 />} />
					<Route path="/hajj/hajj-12" element={<Hajj12 />} />
					<Route path="/hajj/hajj-13" element={<Hajj13 />} />
					<Route path="/hajj/hajj-mistakes" element={<HajjMistakes />} />
					<Route path="/hajj/hajj-supplications" element={<HajjSupplications />} />
					<Route path="/hajj/hajj-notes" element={<HajjNotes />} />

					<Route path="/history" element={<History />} />
					<Route path="/dua" element={<Dua />} />
					<Route path="/places-of-interest" element={<POI />} />
					<Route path="/lectures" element={<Lectures />} />

					<Route path="/" element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/changelog" element={<Changelog />} />
					<Route path="/contact" element={<Contact />} />

					<Route path="*" element={<Page404 />} />

				</Routes>

			</Suspense>

		</BrowserRouter >

	);
}

export default AppRoutes;