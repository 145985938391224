import {
	Box,
	Flex,
	HStack,
	Link,
	IconButton,
	Button,
	Menu,
	MenuButton,
	useDisclosure,
	Stack,
	useColorMode
} from '@chakra-ui/react';

import { HamburgerIcon, CloseIcon, MoonIcon, SunIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { FaShareAlt } from 'react-icons/fa';

import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'

import { ReactComponent as LogoLight } from '../assets/svg/logo.svg';
import { ReactComponent as LogoDark } from '../assets/svg/logo-dark.svg';


const Links =
	[
		{ "icon": "home", "path": "/", "title": "Home" },
		{ "icon": "kaaba", "path": "/umrah", "title": "Umrah" },
		{ "icon": "kaaba", "path": "/hajj", "title": "Hajj" },
		{ "icon": "brain", "path": "/history", "title": "History" },
		{ "icon": "praying-hands", "path": "/dua", "title": "Dua" },
		{ "icon": "map-marked", "path": "/places-of-interest", "title": "POI" },
		{ "icon": "file-audio", "path": "/lectures", "title": "Lectures" },
		{ "icon": "info", "path": "/about", "title": "About" },
		{ "icon": "link", "path": "/changelog", "title": "Changelog" },
		{ "icon": "envelope", "path": "/contact", "title": "Contact" },
		//		{ "icon": "donate", "path": "/donate", "title": "Donate" }
	]


const Header = () => {

	const handleShare = () => {

		if (navigator.share) {
			navigator
				.share({
					title: document.title,
					url: "https://pilgrimcentral.com"
				})
				.then(() => {
					console.log("Shared successfully.");
				})
				.catch(error => {
					console.log("There was an error sharing:", error);
				});
		}
	}

	const { colorMode, toggleColorMode } = useColorMode();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();


	return (

		<Box px={4}>
			<Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
				<IconButton
					size={'md'}
					icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
					aria-label={'Open Menu'}
					display={{ md: 'none' }}
					onClick={isOpen ? onClose : onOpen}
				/>
				<HStack spacing={8} alignItems={'center'}>

					<Box onClick={() => navigate("./")} cursor={"pointer"}>

						{
							colorMode === 'light' &&
							<LogoLight />
						}

						{
							colorMode === 'dark' &&
							<LogoDark />
						}

					</Box>

					<HStack

						spacing={4}
						display={{ base: 'none', md: 'flex' }}>
						{Links.map((link, index) => (
							<Link key={index} as={ReactRouterLink} to={link.path}>{link.title}</Link>
						))}
						<Link href='https://muslimcentral.com/donate' isExternal>
								<Button colorScheme='pink'>
									Donate <ExternalLinkIcon mx='2px' />
								</Button>
							</Link>
					</HStack>
				</HStack>

				<Flex alignItems={'center'}>
					<Menu>

						<MenuButton
							as={Button}
							size={'sm'}
							cursor={'pointer'}
							minW={0}
							onClick={() => handleShare()}
							mr={2}
						>
							<FaShareAlt />
						</MenuButton>

						<MenuButton
							as={Button}
							size={'sm'}
							cursor={'pointer'}
							minW={0}
							onClick={toggleColorMode}>
							{colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
						</MenuButton>
					</Menu>
				</Flex>

			</Flex>

			{isOpen ? (
				<Box pb={4} display={{ md: 'none' }}>
					<Stack as={'nav'} spacing={4}>
						{Links.map((link, index) => (
							<Link key={index} as={ReactRouterLink} to={link.path}>{link.title}</Link>
						))}
						<Link href='https://muslimcentral.com/donate' isExternal>
								<Button colorScheme='pink'>
									Donate <ExternalLinkIcon mx='2px' />
								</Button>
							</Link>
					</Stack>
				</Box>
			) : null}
		</Box>

	)
}

export default Header;