// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react"
import { ThemeConfig } from '@chakra-ui/react'

import '@fontsource/open-sans/700.css'
import "@fontsource/sora"


/* Branding

Light Blue (60%)
HEX #70ccdc
RGB 112, 205, 220
CMYK 51 0 13 0

Blue (20%)
HEX #0e72b9
RGB 14, 115, 186
CMYK 87 51 0 0

Grey (40%)
HEX #343434
RGB 52 52 52
CMYK 69 63 62 57

*/

const brandPrimaryColor = "#70ccdc"
const brandSecondaryColor = "#0e72b9"
// const brand3Color = "#343434"


export function AppUI() {

	// 2. Add your color mode config
	const config: ThemeConfig = {
		initialColorMode: 'light',
		useSystemColorMode: false,
	}


	// 3. extend the theme
	const theme = extendTheme({

		config, fonts: {
			heading: 'Open Sans, sans-serif',
			body: 'Sora, sans-serif',

		},

		colors: {
			brand: {
				primary: brandPrimaryColor,
				secondary: brandSecondaryColor,
			},

		},

		styles: {
			global: {
				blockquote: {
					borderLeft: "3px solid " + brandSecondaryColor,
					padding: "0.5rem 1rem",
					marginBottom: "5",
					marginTop: "5",
				},

				/*
							h1: {
								fontSize: "lg",
								color: brandSecondaryColor
							},
							h2: {
								fontSize: "md",
								color: brandPrimaryColor
							},
							h3: {
								fontSize: "16px"
							},
								*/
			}
		}


	})

	return theme

}

export default AppUI;