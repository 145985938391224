import { Link as RouterLink } from "react-router-dom";

import {
	Box,
	Heading,
	Container,
	Text,
	Button,
	Stack,
	Link,
	HStack
} from '@chakra-ui/react';

import { FaKaaba } from 'react-icons/fa';



const HomeIntro = () => {


	return (

		<Container maxW={'3xl'}>
			<Stack
				as={Box}
				textAlign={'center'}
				spacing={{ base: 8, md: 14 }}
				py={{ base: 20, md: 36 }}>
				<Heading
					fontWeight={600}
					fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
					lineHeight={'110%'}>
					Pilgrim Central
					<br />

					<Text as={'span'} color={'#70ccdc'}>
						Mobile, Tablet & Desktop Friendly</Text>
				</Heading>
				<Text fontSize={'xl'}>
					A concise & easy to use companion for Umrah and Hajj.
				</Text>

				<HStack
					direction={'column'}
					spacing={3}
					align={'center'}
					alignSelf={'center'}
					position={'relative'}
				>

					<Link as={RouterLink} to="/umrah">
						<Button
							colorScheme="blue"
							leftIcon={<FaKaaba />}
							rounded={'full'}
							px={6}
						>Umrah
						</Button>
					</Link>

					<Link as={RouterLink} to='./hajj'>
						<Button
							leftIcon={<FaKaaba />}
							rounded={'full'}
							px={6}
							colorScheme="blue"
						>Hajj
						</Button>
					</Link>

				</HStack>

			</Stack>



		</Container >

	);
}


export default HomeIntro