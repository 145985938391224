const features =
	[
		{
			id: 1,
			title: 'History',
			text: 'We can only appreciate something when we understand the background (history) and this is equally important for our Deen and Ibaadah.'
		},
		{
			id: 2,
			title: 'Dua',
			text: 'Collection of related dua from travelling, spouses, parents, visiting the dead and so on.'
		},
		{
			id: 3,
			title: 'POI',
			text: 'Places of interest in Makkah, Madinah and surrounds.'
		},
		{
			id: 4,
			title: 'Devices',
			text: '99 Names of Allah may be accessed from your desktop, tablet, mobile phone, television and more.'
		},
		{
			id: 5,
			title: 'Platforms',
			text: 'Compatible on most platforms including Android, iOS, Windows, Linux & more. No Ads & completely FREE'
		},
		{
			id: 6,
			title: 'Install',
			text: 'To install 99Names of Allah, simply “Add to Home Screen” via pop-up or via Browser Menu. Yes! Its that simple. No need for searches at any app store.'
		}

	]

export default features